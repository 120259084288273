





















import Vue from 'vue';

export default Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: function() {
        return this.$t('button.save');
      }
    },
    variant: {
      type: String,
      default: 'primary'
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
});
