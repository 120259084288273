



















































































































































import TextInput from '@/components/shared/forms/TextInput.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import { ValidationObserver } from 'vee-validate';
import PhoneInput from '@/components/shared/forms/PhoneInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import { VendorSettingsPage } from '@/model/vendor-settings-page';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useVendorsStore } from '@/store/vendors-store';
import { useCountriesStore } from '@/store/countries-store';
import { VendorFormData } from '@/model/vendor-form-data';
import { InputOption } from '@/model/input-option';

export default Vue.extend({
  name: 'VendorLegalInformationForm',
  components: {
    SelectInput,
    TextInput,
    PhoneInput,
    ValidationObserver,
    SubmitButton
  },
  props: {
    fromVendorSignUp: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapStores(useVendorsStore, useCountriesStore),
    sendingData(): boolean {
      return this.vendorsStore.saving;
    },
    vendor(): VendorFormData {
      return this.vendorsStore.form;
    },
    errors(): any {
      return this.vendorsStore.validationErrors;
    },
    countriesOptions(): InputOption[] {
      return this.countriesStore.countriesOptions;
    }
  },
  methods: {
    async next(form) {
      form.validate();
      if (form.valid) {
        if (!this.vendor.public_name) {
          this.vendor.public_name = this.vendor.name;
        }
        this.$emit('next');
      }
    },
    async onSubmit(form) {
      form.validate();
      if (form.valid) {
        await this.vendorsStore.edit(this.vendor, VendorSettingsPage.LEGAL);
      }
    }
  }
});
