var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.fromVendorSignUp)?_c('h6',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.legalInfo.title'))+" ")]):_vm._e(),_c('div',{staticClass:"alert-light mb-4 rounded p-3"},[_c('div',{staticClass:"small text-dark"},[_vm._v(" "+_vm._s(_vm.$t('vendorSignUp.legalInformation.information'))+" ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(form){return [_c('form',{staticClass:"row",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit(form)}}},[_c('text-input',{staticClass:"col-12",attrs:{"value":_vm.vendor.name,"errors":_vm.errors,"name":"name","label":_vm.$t('label.companyName'),"help-text":_vm.$t('helpText.companyName'),"type":"text","rules":{
          required: true,
          uniqueVendorName: { id: _vm.vendor.id, name: _vm.vendor.name }
        }},on:{"onValueChange":function (value) {
            _vm.vendor.name = value;
          }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.email,"errors":_vm.errors,"name":"email","label":_vm.$t('label.email'),"rules":{
          required: true,
          email: true,
          uniqueVendorEmail: { id: _vm.vendor.id, email: _vm.vendor.email }
        }},on:{"onValueChange":function (value) {
            _vm.vendor.email = value;
          }}}),_c('phone-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.phone ? _vm.vendor.phone : '',"errors":_vm.errors,"name":"phone","label":_vm.$t('label.workPhone'),"rules":"required|phone"},on:{"onValueChange":function (value) {
            _vm.vendor.phone = value;
          }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.uid,"errors":_vm.errors,"optional":true,"name":"uid","label":_vm.$t('label.uidNumber'),"help-text":_vm.$t('helpText.uidNumber'),"type":"text"},on:{"onValueChange":function (value) {
            _vm.vendor.uid = value;
          }}}),_c('text-input',{staticClass:"col-12",attrs:{"value":_vm.vendor.address,"errors":_vm.errors,"name":"address","rules":"required","label":_vm.$t('label.address'),"type":"text","placeholder":"Enter company address"},on:{"onValueChange":function (value) {
            _vm.vendor.address = value;
          }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.zip,"errors":_vm.errors,"name":"zip","rules":"required","label":_vm.$t('label.zip'),"type":"text","placeholder":"Enter zip"},on:{"onValueChange":function (value) {
            _vm.vendor.zip = value;
          }}}),_c('text-input',{staticClass:"col-md-6",attrs:{"value":_vm.vendor.city,"errors":_vm.errors,"name":"city","rules":"required","label":_vm.$t('label.city'),"type":"text","placeholder":"Enter city"},on:{"onValueChange":function (value) {
            _vm.vendor.city = value;
          }}}),_c('select-input',{staticClass:"col-md-6",attrs:{"label":_vm.$t('label.country'),"name":"country","options":_vm.countriesOptions,"rules":"required","value":_vm.vendor.country},on:{"onValueChange":function (value) { return (_vm.vendor.country = value); }}}),(!_vm.fromVendorSignUp)?_c('submit-button',{staticClass:"col-12",attrs:{"loading":_vm.sendingData,"label":"Enregistrer"}}):_vm._e()],1),(_vm.fromVendorSignUp)?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-6 offset-6"},[_c('button',{staticClass:"btn btn-primary w-100",on:{"click":function () { return _vm.next(form); }}},[_vm._v(" "+_vm._s(_vm.$t('button.next'))+" "),_c('i',{staticClass:"fas fa-arrow-right"})])])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }