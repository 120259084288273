import { defineStore } from 'pinia';
import { Country } from '@/model/country';
import i18n from '@/config/i18n';
import countriesService from '@/services/countries.service';

export const useCountriesStore = defineStore('countries', {
  state: () => ({
    entities: [] as Country[],
    fetching: false as boolean,
    failure: false as boolean
  }),
  getters: {
    countriesOptions: state => {
      const locale = i18n.locale;
      return state.entities.map(country => ({
        value: country.alpha2Code,
        label: locale === 'en' ? country.name : country.translations[locale]
      }));
    }
  },
  actions: {
    async findAll() {
      if (this.entities.length > 0) {
        return;
      }
      this.fetching = true;
      this.failure = false;
      try {
        this.entities = await countriesService.findAll();
        this.fetching = false;
      } catch (exception) {
        this.failure = true;
        this.fetching = false;
      }
    }
  }
});
